import axios from 'axios'
import makeConfig from './token'
import { apiURL } from '../util/config'
import useToast from '../util/promiseToast'

const useDiscountCodeService = () => {
  const baseUrl = `${apiURL}/discount_codes`
  const { showErrorToast } = useToast()

  const createNew = ({ text, description, endTime, percent }) => {
    const config = makeConfig()
    const request = axios.post(
      `${baseUrl}`,
      { newDiscountCode: { text, description, endTime, percent } },
      config
    )
    return request.then((response) => response.data)
  }

  const deleteCode = ({ text }) => {
    const config = makeConfig()
    const request = axios.delete(`${baseUrl}/${text}`, config)
    return request.then((response) => response.data)
  }

  const getAll = () => {
    const config = makeConfig()
    const request = axios.get(`${baseUrl}/`, config)
    return request.then((response) => response.data)
  }

  const checkDiscountCode = (code) => {
    const config = makeConfig()
    const request = axios.get(`${baseUrl}/${code}`, config)
    return request.then((response) => response.data)
  }
  return {
    createNew,
    getAll,
    deleteCode,
    checkDiscountCode,
  }
}
export default useDiscountCodeService
